var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "middle-ware-wrapper" }, [
    _vm.loadState == 0
      ? _c("div", [_vm._v("正在进入系统中.....")])
      : _vm.loadState == 1
      ? _c("div", [
          _c("i", { staticClass: "el-icon-success" }),
          _c("span", [_vm._v("登录成功,正在跳转....")])
        ])
      : _vm.loadState == 2
      ? _c("div", [
          _c("i", { staticClass: "el-icon-error" }),
          _c("span", [_vm._v("登录失败,请关闭窗口重试")])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }