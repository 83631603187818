import LocalStorageDBManger from "../../db/LocalStorageDBManger";
import { HudGlobal } from "../../util/HudGlobal";
import StoreDBManger from "../../util/StoreDBManger";
import userUtil from "../../util/userUtil";
import { mapActions } from "vuex";
import { gotoMeetingList, gotoMeetingPrepare } from "../meeting/utils/MeetingRouter";
import { gotoHomeList, gotoTrainList } from "./utils/ControlRouter";
import { getCurrentSystemType, getCurrentUserId, setCurrentSubjectCode, setCurrentSystemType } from "../../util/user/UserManger";
import { SYSTEM_TYPE } from "../../util/constants/EnumConstants";
import { removeOfflineRecordIdToDB } from "../../util/db/TrainDBManger";
import planApi from "../../api/planApi";
import StoreTrainDBManger from "../../db/StoreTrainDBManger";

export default {
    name: "Index",
    components: {},
    data() {
        return {
            // 路由参数
            routerParam: {
                userId: null,
                tempId: null,
                type: SYSTEM_TYPE.TRAIN, //train  培训列表
                siteOrgId: "", // 组织id
                subjectCode: "", // 类别
                planId: "",
                f: null, // 源地址
            },

            loadState: 0, // 加载中 1 成功 2失败
        };
    },
    mounted() {
        this.$message.closeAll();
        const query = this.$route.query;
        Object.assign(this.routerParam, query);

        console.log("获取路由参数====", this.routerParam);
        this.$emit("joinSystem", this.routerParam);

        //  保存系统系统
        if (query && query.type) {
            setCurrentSystemType(query.type, this.routerParam.userId);
        } else {
            setCurrentSystemType(SYSTEM_TYPE.TRAIN, this.routerParam.userId);
        }
        document.title = getCurrentSystemType() == SYSTEM_TYPE.MEETING ? "现场会议" : "现场学习";

        // 保存组织id
        this.setSiteOrgId(this.routerParam.siteOrgId);
        // 保存登录源
        this.saveRouterSourcePath(query);
        this.loadState = 0;

        // 检测用户登录系统
        setTimeout(() => {
            this.checkUserLogin();
        }, 0);
    },
    methods: {
        ...mapActions(["setSiteOrgId"]),
        /**
         * 保存跳转来源
         */
        saveRouterSourcePath(query) {
            let fromLocation = query.f;
            // 预防解析不完#号的情况
            let href = window.location.href;
            let substring = href.substring(href.indexOf("f=") + 2);
            if (substring.indexOf("#") !== -1) {
                let uriComponent = decodeURIComponent(substring);
                fromLocation = uriComponent;
            }
            LocalStorageDBManger.saveDataToLocalStorage("fromLogin", fromLocation);
        },

        /**
         * 登录系统
         */
        checkUserLogin() {
            // 1. 参数检查

            if (this.routerParam.tempId && this.routerParam.tempId.length > 0) {
                const userId = getCurrentUserId();

                // 如果没有登录过或者 登录之后不同的用户需要重新登陆
                if (!userId || userId.length == 0 || userId !== this.routerParam.userId) {
                    this.getUserLoginInfo({
                        tempId: this.routerParam.tempId,
                        userId: this.routerParam.userId,
                    });
                    return false;
                }
            }

            // 2.只有userId可以查看
            if (this.routerParam.userId && this.routerParam.userId.length > 0) {
                this.getUserLoginInfo({
                    tempId: this.routerParam.tempId,
                    userId: this.routerParam.userId,
                });
                return false;
            }

            // 2. 没有token
            const token = localStorage.getItem("token");
            if (!!token) {
                HudGlobal.showUniversalAlert("页面失效或非法进入, 请从管理端进入本页面, 确定关闭本页面!", { showClose: false }).then(() => {
                    window.close();
                });
                return false;
            }

            const expired = jsonWebToken.isExpired(authorization);
            // 0未过期, 1即将过期, 2已过期
            if (expired === 2) {
                this.getUserLoginInfo({
                    tempId: this.routerParam.tempId,
                    userId: this.routerParam.userId,
                });
            } else {
                // 有用户则直接跳转到会议列表
                this.handleLoginSuccess();
            }
        },
        /*
         * 获取用户登陆信息
         * */
        getUserLoginInfo(params) {
            const that = this;
            this.loadState = 0;
            that.clearLocalUserData();
            userUtil
                .offlineLogin(params)
                .then((res) => {
                    if (res.success) {
                        that.loadState = 1;
                        // 加载成功之后获取其他参数lzb2021-07-30
                        that.$emit("loginSuccess");
                        that.handleLoginSuccess();
                    } else {
                        that.loadState = 2;
                        console.log("登录失败==", res);
                        // that.handleLoginFail(res);
                    }
                })
                .catch((err) => {
                    console.log("登录失败===", err);
                    that.loadState = 2;
                    // that.logoutToClose();
                });
        },

        /***
         * 处理登录成功
         */
        handleLoginSuccess() {
            if (this.routerParam.type == SYSTEM_TYPE.MEETING) {
                setCurrentSystemType(SYSTEM_TYPE.MEETING);
                setCurrentSubjectCode(this.routerParam.subjectCode);

                // 有计划id- 跳转到会议准备
                if (this.routerParam.planId && this.routerParam.planId.length > 0) {
                    this.loadPlanOneInfo({ planId: this.routerParam.planId }, () => {
                        gotoMeetingPrepare({ planId: this.routerParam.planId });
                    });
                } else {
                    gotoMeetingList({ subjectCode: this.routerParam.subjectCode });
                }
            } else {
                setCurrentSystemType(SYSTEM_TYPE.TRAIN);
                gotoTrainList({});
            }
        },

        /**
         * 处理请求失败
         */
        handleLoginFail(res) {
            const that = this;
            const code = res.code;
            if (code === 406 || code === 405) {
                HudGlobal.showUniversalAlert("您已在其它登录, 确定关闭页面", {
                    showClose: false,
                    confirmButtonText: "确定", // 2020/07/02 需求改动 fbz
                }).then(() => {
                    const urlPath = that.routerParam.f;
                    if (urlPath && urlPath.length > 0) {
                        that.router.push(urlPath);
                    } else {
                        gotoHomeList({});
                    }
                });
                return false;
            }
            that.logoutToClose();
        },
        /**
         * 获取计划信息
         */
        loadPlanOneInfo(params, cb) {
            const that = this;
            planApi.getPlanOne(params).then((res) => {
                if (res.success && res.data) {
                    const planInfo = res.data.planBaseInfoDto || {};
                    StoreTrainDBManger.saveCurrentPlanItemToDB(planInfo);
                }
                cb && cb();
            });
        },

        /**
         * 清除本地用户数据
         */
        clearLocalUserData() {
            // 清空本地数据, 以下所有数据重新请求
            userUtil.removeUserData();
            // 移除 offlineid lzb 2020-06-25 socket版本
            removeOfflineRecordIdToDB();
        },

        /*
         * 强制关闭页面
         * */
        logoutToClose() {
            HudGlobal.showUniversalAlert("现场登录失败, 请返回上级页面重新进入!", { showClose: false }).then(() => {
                window.close();
            });
        },
    },
};
