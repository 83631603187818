import router from "../../../router";
import { SYSTEM_TYPE } from "../../../util/constants/EnumConstants";
import { COMMON, MEETING } from "../../../util/constants/RouteConstant";

/**
 * 跳转到会议列表
 */
export const gotoMeetingList = ({ ...obj }) => {
    router.push({
        path: MEETING.MEETING_LIST,
        query: {
            type: SYSTEM_TYPE.MEETING,
            ...obj,
        },
    });
};

/**
 * 跳转到会议准备
 */
export const gotoMeetingPrepare = ({ ...obj }) => {
    router.push({
        path: MEETING.MEETING_PREPARE,
        query: {
            type: SYSTEM_TYPE.MEETING,
            ...obj,
        },
    });
};
/**
 * 跳转到签到
 */
export const gotoMeetingSignIn = ({ ...obj }) => {
    router.push({
        path: COMMON.VERIFY_PHOTO,
        query: {
            type: SYSTEM_TYPE.MEETING,
            ...obj,
        },
    });
};
